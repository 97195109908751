import { Directive, DirectiveBinding } from 'vue';
import { useAuthStore } from '@/stores/auth';

export const canDirective: Directive = {
  async beforeMount(
    el: HTMLElement,
    binding: DirectiveBinding<string | string[]>,
  ) {
    if (binding.arg !== 'display') return;

    const authStore = useAuthStore();
    if (Array.isArray(binding.value)) {
      const canDisplay = authStore.permissions.some((claimPermission) =>
        binding.value.includes(claimPermission),
      );
      if (!canDisplay) {
        // eslint-disable-next-line no-param-reassign
        el.style.display = 'none';
      }
      return;
    }

    const canDisplay = authStore.permissions.includes(binding.value);
    if (!canDisplay) {
      // eslint-disable-next-line no-param-reassign
      el.style.display = 'none';
    }
  },
};
