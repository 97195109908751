<script setup lang="ts">
import { type Locale, useI18n } from '../../composables/i18n';
import {
  type SigningCompletionData,
  isErrorDto,
  type CheckResponseDto,
} from 'careos-bankid-adapter';
import { initApi } from '../../api/bankid-client';
import { computed, onUnmounted, ref } from 'vue';
import QrScanPending from './QrScanPending.vue';
import QrScanFailed from './QrScanFailed.vue';
import QrScanComplete from './QrScanComplete.vue';
import QrScanError, { type ScanError } from './QrScanError.vue';
import { enforceExhaustive } from '../../utils/enforce-exhaustive';

const props = defineProps<{
  locale: Locale;
  transactionId: string;
  visibleText: string;
  accessToken: string;
}>();
const api = initApi(computed(() => props.accessToken));
const { t } = useI18n(props.locale);

const emit = defineEmits<{
  cancel: [];
  completion: [completionData: SigningCompletionData];
}>();

const getResultTimeout = ref();
const checkData = ref<CheckResponseDto>();
const error = ref<ScanError>();
const inProgress = ref(false);

const abort = async () => {
  clearTimeout(getResultTimeout.value);
  if (inProgress.value && checkData.value?.orderRef) {
    await api.cancel(checkData.value?.orderRef);
  }
};

const handleError = async (err: unknown) => {
  await abort();
  if (isErrorDto(err)) {
    error.value = err;
    return;
  }
  error.value = { errorCode: 'careosUnknown' };
};

const poll = async () => {
  try {
    const res = await api.sign({
      visibleText: props.visibleText,
      transactionId: props.transactionId,
    });

    inProgress.value = true;
    const getResult = async () => {
      try {
        const response = await api.check(res.orderRef);
        checkData.value = response;
        const { status } = response;

        switch (status) {
          case 'pending':
            getResultTimeout.value = setTimeout(getResult, 1000);
            break;

          case 'complete':
            inProgress.value = false;
            emit('completion', response.completionData);
            await abort();
            break;

          case 'failed':
            inProgress.value = false;
            await abort();
            break;

          default:
            enforceExhaustive(status);
        }
      } catch (err) {
        handleError(err);
      }
    };
    await getResult();
  } catch (err) {
    handleError(err);
  }
};

const handleTryAgain = async () => {
  checkData.value = undefined;
  await poll();
};

const heading = computed(() =>
  checkData.value?.status === 'complete'
    ? t('sign-success')
    : t('sign-with-bankid'),
);

const statusColor = computed(() => {
  if (checkData.value?.status === 'failed' || error.value) {
    return 'bg-red-100 text-red-950';
  } else if (checkData.value?.status === 'complete') {
    return 'bg-green-100 text-green-900';
  } else {
    return 'bg-white';
  }
});

onUnmounted(() => {
  abort();
});

poll();
</script>

<template>
  <div
    :class="[
      'text-center flex flex-col items-center p-8 md:p-16 min-h-[500px] w-full relative sm:w-[500px]',
      statusColor,
    ]"
  >
    <button
      class="absolute top-0 left-0 p-4 font-bold"
      @click="$emit('cancel')"
    >
      X
    </button>
    <QrScanError v-if="error" :error="error" :locale="locale" />
    <template v-else>
      <h2 class="text-3xl mb-8 font-extrabold">{{ heading }}</h2>

      <QrScanPending
        v-if="checkData?.status === 'pending'"
        :pending-data="checkData"
        :locale="locale"
      />

      <QrScanComplete v-else-if="checkData?.status === 'complete'" />

      <QrScanFailed
        v-else-if="checkData?.status === 'failed'"
        :failed-data="checkData"
        :locale="locale"
        @on-cancel="$emit('cancel')"
        @on-try-again="handleTryAgain"
      />
    </template>
  </div>
</template>
