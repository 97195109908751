import { z } from 'zod';
import { BankIdCompletionDataSchema } from '../bankid-types/bankid-completion-data';
import { KnownHintCodeSchema } from '../bankid-types/const/hint-code';
import { BankIdStatus } from '../bankid-types/const/status';
import { UnknownCode } from '../const/unknown';

export const SigningCompletionDataSchema = BankIdCompletionDataSchema.pick({
  signature: true,
  user: true,
}).extend({ completionDate: z.string().datetime() });
export type SigningCompletionData = z.infer<typeof SigningCompletionDataSchema>;

export const HintCodeSchema = z.enum([
  ...KnownHintCodeSchema.options,
  UnknownCode,
]);

export const HintCode = HintCodeSchema.Values;
export type HintCode = z.infer<typeof HintCodeSchema>;

const PendingResponseDtoSchema = z.object({
  orderRef: z.string(),
  status: z.literal(BankIdStatus.pending),
  qrText: z.string(),
  hintCode: HintCodeSchema,
});
export type PendingResponseDto = z.infer<typeof PendingResponseDtoSchema>;

const FailedResponseDtoSchema = z.object({
  orderRef: z.string(),
  status: z.literal(BankIdStatus.failed),
  hintCode: HintCodeSchema,
});

export type FailedResponseDto = z.infer<typeof FailedResponseDtoSchema>;

const CompleteResponseDtoSchema = z.object({
  orderRef: z.string(),
  status: z.literal(BankIdStatus.complete),
  completionData: SigningCompletionDataSchema,
});

export type CompleteResponseDto = z.infer<typeof CompleteResponseDtoSchema>;

export const CheckResponseDtoSchema = z.discriminatedUnion('status', [
  PendingResponseDtoSchema,
  FailedResponseDtoSchema,
  CompleteResponseDtoSchema,
]);

export type CheckResponseDto = z.infer<typeof CheckResponseDtoSchema>;

export const isSigningCompletionData = (
  data: unknown,
): data is SigningCompletionData =>
  SigningCompletionDataSchema.safeParse(data).success;
