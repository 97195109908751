import { z } from 'zod';

export const SpecimenDeviationTypeSchema = z.enum([
  'EMPTY_TUBE',
  'ABSORBENT_MATERIAL_INSIDE',
  'INADEQUATE_SAMPLE',
  'TAMPER_SEAL_BROKEN',
  'LEAKAGE',
  'DEBRIS_IN_SAMPLE',
  'UNSCANNABLE_BARCODE',
  'INCORRECT_BARCODE_PLACEMENT',
  'CONTAMINATED_SAMPLE',
  'NO_BARCODE',
  'DUPLICATE_BARCODE',
  'MISSING_REQUISITION',
  'MISSING_B_SPECIMEN',
  'MISMATCHED_B_SPECIMEN',
  'MISMATCHED_SPECIMEN_TYPE',
  'NO_TAMPER_SEAL',
  'INSUFFICIENT_SAMPLING',
  'MISMATCHED_SAMPLING_KIT',
  'UNKNOWN_SAMPLING_KIT',
]);

export const DeviationsToReadableSchema = z.enum([
  'Empty tube',
  'Absorbent material inside',
  'Inadequate sample',
  'Tamper seal broken',
  'Leaked sample',
  'Debris inside the sample',
  'Unscannable barcode',
  'Incorrect barcode placement',
  'Contaminated sample',
  'No barcode',
  'Duplicate barcode',
  'Missing requisition',
  'Missing A or B specimen',
  'Mismatched B specimen',
  'Mismatched specimen type',
  'No tamper seal',
  'Insufficient sampling (not enough sampling material)',
  'Mismatched sampling kit',
  'Incorrect sampling kit',
]);

export const DeviationsToLabelMappingSchema = z.record(
  SpecimenDeviationTypeSchema,
  DeviationsToReadableSchema,
);

export type SpecimenDeviationType = z.infer<typeof SpecimenDeviationTypeSchema>;
export const SpecimenDeviationType = SpecimenDeviationTypeSchema.Enum;

export type DeviationsToReadable = z.infer<
  typeof DeviationsToLabelMappingSchema
>;
export const DeviationsToReadable = DeviationsToReadableSchema.Enum;

export const deviationsToReadable: DeviationsToReadable = {
  EMPTY_TUBE: 'Empty tube',
  ABSORBENT_MATERIAL_INSIDE: 'Absorbent material inside',
  INADEQUATE_SAMPLE: 'Inadequate sample',
  TAMPER_SEAL_BROKEN: 'Tamper seal broken',
  LEAKAGE: 'Leaked sample',
  DEBRIS_IN_SAMPLE: 'Debris inside the sample',
  UNSCANNABLE_BARCODE: 'Unscannable barcode',
  INCORRECT_BARCODE_PLACEMENT: 'Incorrect barcode placement',
  CONTAMINATED_SAMPLE: 'Contaminated sample',
  NO_BARCODE: 'No barcode',
  DUPLICATE_BARCODE: 'Duplicate barcode',
  MISSING_REQUISITION: 'Missing requisition',
  MISSING_B_SPECIMEN: 'Missing A or B specimen',
  MISMATCHED_B_SPECIMEN: 'Mismatched B specimen',
  MISMATCHED_SPECIMEN_TYPE: 'Mismatched specimen type',
  NO_TAMPER_SEAL: 'No tamper seal',
  INSUFFICIENT_SAMPLING: 'Insufficient sampling (not enough sampling material)',
  MISMATCHED_SAMPLING_KIT: 'Mismatched sampling kit',
  UNKNOWN_SAMPLING_KIT: 'Incorrect sampling kit',
};

export const DeviationSchema = z.object({
  /**
   * Message that describes the deviation.
   */
  message: z.string(),
  /**
   * {@link SpecimenDeviationType} that describe the error type.
   * This enable consumers to construct projections based on known deviations.
   */
  specimenDeviationType: SpecimenDeviationTypeSchema,
});
export type Deviation = z.infer<typeof DeviationSchema>;
