import { FormKitNode } from '@formkit/core';
import personnummer from 'personnummer';

export function formatPersonalNumber(node: FormKitNode) {
  node.hook.input((value, next) => {
    if (!value) {
      return next(value);
    }
    const shouldFormat = value.length !== 13;
    if (value.length >= 10) {
      const isValidPersonalNumber = personnummer.valid(value);

      if (isValidPersonalNumber && shouldFormat) {
        const parsedPersonalNumber = personnummer.parse(value);

        const { fullYear, month, day, sep, num, check } = parsedPersonalNumber;

        const formattedPersonalNumber = `${fullYear}${month}${day}${sep}${num}${check}`;

        return next(formattedPersonalNumber);
      }
    }
    return next(value);
  });
}
