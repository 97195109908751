import z from 'zod';
import {
  DonorSchema,
  SamplingSessionReason,
  SamplingSessionStatusSchema,
  ToxicologySamplingSessionDetailsSchema,
} from '../../../common';

export const SamplingSessionSchema = z.object({
  samplingDate: z.string(),
  sessionId: z.string(),
  reasonForTesting: SamplingSessionReason,
  employer: z.string(),
  notes: z.string().nullish(),
  status: SamplingSessionStatusSchema,
  donor: DonorSchema.optional(),
  sampleCount: z.number().positive(),
  samplingDetails: z.array(ToxicologySamplingSessionDetailsSchema).min(1),
});

export type SamplingSession = z.infer<typeof SamplingSessionSchema>;
