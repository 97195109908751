export enum BarcodeSystemURI {
  /**
   * Barcode system for ABC specimen.
   */
  AbcSpecimen = 'uri:careos.io/specimen/abclabs',
  /**
   * Barcode system for ABC parcels.
   */
  AbcParcel = 'uri:careos.io/parcels/abclabs',
  /**
   * Barcode system for ABC racks.
   */
  AbcRack = 'uri:careos.io/racks/abclabs',
  /**
   * Barcode system for aliquot specimens for DoA line.
   */
  AliquotSpecimen = 'uri:careos.io/parcels/aliquot',
  /**
   * Barcode system for B specimens for DoA line.
   */
  BSpecimen = 'uri:careos.io/parcels/bspecimen',
  /**
   * Barcode system for Labportalen specimen
   */
  LabportalenSpecimen = 'uri:careos.io/specimen/labportalen',
}
