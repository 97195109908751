import { z } from 'zod';
import { PanelSubstanceSchema } from '../../panel';
export const GetSubstancesByIdResponseDtoSchema = z.object({
  substances: PanelSubstanceSchema.omit({
    isReportedQualitative: true,
  }).array(),
});

export type GetSubstancesByIdResponseDto = z.infer<
  typeof GetSubstancesByIdResponseDtoSchema
>;
