<template>
  <OrderViewWrapper>
    <OrderViewCard
      :title="t('samplingKits.title')"
      :forward-label="t('general.next_step')"
      :disable-forwards="!selectedSamplingKit"
      :show-backwards="true"
      :show-swedac-logo="isSwedacAccredited"
      @action-left="previousStep"
      @action-right="nextStep"
    >
      <div class="grid grid-rows-2 gap-4">
        <div v-for="samplingKit in samplingKits" :key="samplingKit">
          <RadioButton
            :label="`${t(`samplingKits.${samplingKit}`)}${superscript(samplingKit)}`"
            :selected="samplingKit === selectedSamplingKit"
            @click="selectSamplingKit(samplingKit)"
          />
        </div>
      </div>
      <AccreditationLabInfo
        v-if="isSwedacAccredited"
        :has-qualitative-substance="orderViewStore.hasQualitativeSubstance"
        class="mt-2"
      />
    </OrderViewCard>
  </OrderViewWrapper>
</template>

<script setup lang="ts">
import AccreditationLabInfo from '@/components/AccreditationLabInfo.vue';
import { router } from '@/router';
import { useI18n } from 'vue-i18n';
import OrderViewCard from '../../components/OrderViewCard.vue';
import OrderViewWrapper from '../../components/OrderViewWrapper.vue';
import RadioButton from '../../components/RadioButton.vue';
import { useOrderViewStore } from '../../store/order-view-store';
import { useSamplingKit } from '@/composables/useSamplingKit';
import { useSamplingKitStore } from '@/stores/order/samplingKitStore';
import {
  SamplingKitIdentifier,
  isUnaccreditedPethSamplingKit,
} from '@careos/identifiers';
import { toRefs } from 'vue';

const { t } = useI18n();
const orderViewStore = useOrderViewStore();
const samplingKitStore = useSamplingKitStore();
const { selectedSamplingKit } = toRefs(samplingKitStore);
const { selectedTestType } = toRefs(orderViewStore);
const {
  handleSamplingKitChange,
  fetchSamplingKits,
  samplingKits,
  isSwedacAccredited,
} = useSamplingKit();

const superscript = (samplingKit: SamplingKitIdentifier) => {
  return isSwedacAccredited.value && isUnaccreditedPethSamplingKit(samplingKit)
    ? '¹'
    : '';
};

const nextStep = () => {
  if (selectedTestType.value === 'PEth') {
    router.push({
      path: '/order/comment',
    });
    return;
  }
  router.push({
    path: '/order/substances',
  });
};
const previousStep = () => {
  router.go(-1);
};
const selectSamplingKit = (samplingKit: SamplingKitIdentifier) => {
  handleSamplingKitChange(samplingKit);
  nextStep();
};

fetchSamplingKits();
</script>
