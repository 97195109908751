//INFO: We only have 1 substance per sample type we have to do this check.
//If this list gets bigger or the logic gets generalized we have to state this in the config database.

import { z } from 'zod';
import { DoaActivityDefinitionIdentifier } from './activity-definition-identifiers';
import { SamplingKitIdentifier } from './sampling-kit-identifiers';

export const QualitativeSubstanceSchema = z.enum(['GABAPENTIN', '3_CMC']);
export const QualitativeSubstance = QualitativeSubstanceSchema.Enum;

const isQualitativeUrineSubstance = (
  substance: string,
  activityDefinition: string,
) =>
  substance === QualitativeSubstance['3_CMC'] &&
  activityDefinition === DoaActivityDefinitionIdentifier.DOA_URINE_SAMPLING;

const isQualitativeSalivaSubstance = (
  substance: string,
  activityDefinition: string,
  samplingKit?: string,
) =>
  substance === QualitativeSubstance.GABAPENTIN &&
  activityDefinition === DoaActivityDefinitionIdentifier.DOA_SALIVA_SAMPLING &&
  (samplingKit === SamplingKitIdentifier.GREINER || samplingKit === undefined); // INFO: Since the old saliva samples were not marked with a sampling kit we have to allow undefined here.

export const isQualitativeSubstance = (
  substance: string,
  activityDefinition: string,
  samplingKit?: string,
) => {
  return (
    isQualitativeUrineSubstance(substance, activityDefinition) ||
    isQualitativeSalivaSubstance(substance, activityDefinition, samplingKit)
  );
};
