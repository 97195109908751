import { z } from 'zod';
import { AnnotationSchema } from './annotation.interface';
import { CodeableConceptSchema } from './codeable-concept.interface';
import { IdentifierSchema } from './identifier.interface';
import { InterpretationCodableConceptSchema } from './interpretation-codable-concept.interface';
import { IsoDateTimeSchema } from './iso-date.type';
import { QuantitySchema } from './quantity.interface';
import { RangeSchema } from './range.interface';
import { ReferenceRangeSchema } from './reference-range.interface';
import { ReferenceSchema } from './reference.interface';

/**
 * Schema for {@link ObservationStatus}
 */
export const ObservationStatusSchema = z.enum([
  'registered',
  'preliminary',
  'final',
  'amended',
]);

/**
 * Current state of the observation.
 */
export type ObservationStatus = z.infer<typeof ObservationStatusSchema>;

/**
 * Schema for {@link DataAbsentReason}
 */
export const DataAbsentReasonSchema = z.enum([
  'unknown',
  'masked',
  'error',
  'not-performed',
  'unsupported',
]);
export type DataAbsentReason = z.infer<typeof DataAbsentReasonSchema>;

/**
 * Schema for {@link BaseObservation}
 */
export const BaseObservationSchema = z.object({
  /**
   * Unique resource ID.
   */
  observationId: z.string(),

  /**
   * Allows observations to be distinguished and referenced.
   * eg. the barcode of the specimen or any other external identifiers
   */
  identifiers: z.array(IdentifierSchema),

  /**
   * Status of the test result value.
   */
  status: ObservationStatusSchema,

  /**
   * Marker that has been tested for.
   */
  code: CodeableConceptSchema,

  /**
   * When the observation was clinically made.
   */
  effectiveDateTime: IsoDateTimeSchema,

  /**
   * {@link Specimen} on which the observation has been made.
   */
  specimen: ReferenceSchema(z.enum(['Specimen'])),

  /**
   * {@link Organization} that generated and is responsible for the observation.
   */
  performer: ReferenceSchema(z.enum(['Organization'])),

  /**
   * Reference range that provides guidance on how to interpret the value.
   */
  referenceRanges: ReferenceRangeSchema.array().optional(),

  /**
   * Comments about the observation.
   */
  note: z.array(AnnotationSchema).optional(),

  /**
   * A categorical assessment of an observation value. For example, high, low, normal.
   * Reference {@link https://build.fhir.org/observation-definitions.html#Observation.interpretation|FHIR}
   */
  interpretation: z.array(InterpretationCodableConceptSchema),

  /**
   * Identifier to the entity who made the observation if any
   */
  observedBy: IdentifierSchema.optional(),

  /**
   * In addition to the required category valueset, this element allows various
   * categorization schemes based on the owner’s definition of the category
   * and effectively multiple categories can be used at once. The level of
   * granularity is defined by the category concepts in the value set.
   */
  category: CodeableConceptSchema.optional(),
});

/**
 * Common fields for different variants of {@link Observation}.
 */
export type BaseObservation = z.infer<typeof BaseObservationSchema>;

/**
 * Schema for {@link ValueStringObservation}
 */
export const ValueStringObservationSchema = BaseObservationSchema.extend({
  /**
   * Qualitative value of a test result.
   */
  valueString: z.string(),
});
/**
 * An {@link Observation} with a qualitative (string) value.
 */
export type ValueStringObservation = z.infer<
  typeof ValueStringObservationSchema
>;

/**
 *
 * Checks if an observation is a {@link ValueStringObservation}
 * @example
 * ```ts
 * if (isValueStringObservation(obs)) {
 *  // No typerror here
 *  obs.valueString
 * }
 * ```
 */
export const isValueStringObservation = (
  obs?: Observation,
): obs is ValueStringObservation =>
  ValueStringObservationSchema.safeParse(obs).success;

/**
 * Schema for {@link ValueQuantityObservation}
 */
export const ValueQuantityObservationSchema = BaseObservationSchema.extend({
  /**
   * Qualitative value of a test result.
   */
  valueQuantity: QuantitySchema,
});

/**
 * An {@link Observation} with a quantitative value.
 */
export type ValueQuantityObservation = z.infer<
  typeof ValueQuantityObservationSchema
>;

/**
 *
 * Checks if an observation is a {@link ValueQuantityObservation}
 * @example
 * ```ts
 * if (isValueQuantityObservation(obs)) {
 *  // No typerror here
 *  obs.valueQuantity
 * }
 * ```
 */
export const isValueQuantityObservation = (
  obs?: Observation,
): obs is ValueQuantityObservation =>
  ValueQuantityObservationSchema.safeParse(obs).success;

/**
 * Schema for {@link ValueRangeObservation}
 */
export const ValueRangeObservationSchema = BaseObservationSchema.extend({
  /**
   * Range of quantitative values of the test result.
   */
  valueRange: RangeSchema,
});

/**
 * An {@link Observation} with a range of quantitative values.
 */
export type ValueRangeObservation = z.infer<typeof ValueRangeObservationSchema>;

/**
 *
 * Checks if an observation is a {@link ValueRangeObservation}
 * @example
 * ```ts
 * if (isValueRangeObservation(obs)) {
 *  // No typerror here
 *  obs.valueQuantity
 * }
 * ```
 */
export const isValueRangeObservation = (
  obs?: Observation,
): obs is ValueRangeObservation =>
  ValueRangeObservationSchema.safeParse(obs).success;

/**
 * Schema for {@link ValueAbsentObservation}
 */
export const ValueAbsentObservationSchema = BaseObservationSchema.extend({
  /**
   * Reason for the absence of a test result.
   */
  dataAbsentReason: DataAbsentReasonSchema,
});

/**
 * An {@link Observation} with an absent result.
 * http://build.fhir.org/valueset-data-absent-reason.html
 */
export type ValueAbsentObservation = z.infer<
  typeof ValueAbsentObservationSchema
>;

/**
 *
 * Checks if an observation is a {@link ValueAbsentObservation}
 * @example
 * ```ts
 * if (isValueAbsentObservation(obs)) {
 *  // No typerror here
 *  obs.valueQuantity
 * }
 * ```
 */
export const isValueAbsentObservation = (
  obs?: Observation,
): obs is ValueAbsentObservation =>
  ValueAbsentObservationSchema.safeParse(obs).success;

export const ObservationSchema = z.union([
  ValueQuantityObservationSchema,
  ValueRangeObservationSchema,
  ValueStringObservationSchema,
  ValueAbsentObservationSchema,
]);

/**
 * An Observation is generated by a lab to capture the result of a test.
 *
 * Different types of results are supported, or absent results.
 * See {@link ValueQuantityObservation}, {@link ValueStringObservation}, {@link ValueRangeObservation}, {@link ValueAbsentObservation}
 * See {@link BaseObservation} for common content.
 */
export type Observation = z.infer<typeof ObservationSchema>;
