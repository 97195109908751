import {
  DoaActivityDefinitionIdentifier,
  PethActivityDefinitionIdentifier,
  SamplingKitIdentifier,
} from '@careos/identifiers';
import {
  OrderType,
  OrganizationWithEnabledTestTypes,
  TestType,
} from '@careos/organization-api-types';
import { computed, ref, toRaw, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useOrganizations } from '../stores/organizations';

export type Disabled = 'disabled';
export type SamplingKitArgs = {
  samplingKit: SamplingKitIdentifier;
  isEnabled: boolean;
};

export const useOrganizationTestType = (
  testType: TestType,
  initialOrganization: OrganizationWithEnabledTestTypes,
) => {
  const { t } = useI18n();
  const organizationsStore = useOrganizations();
  const cloneOrganization = structuredClone(toRaw(initialOrganization));
  const organization = ref(cloneOrganization);

  const isDoa = computed(() => testType === TestType.DoA);

  const activityDefinitionOptions = computed(() =>
    isDoa.value
      ? DoaActivityDefinitionIdentifier
      : PethActivityDefinitionIdentifier,
  );

  const orderTypeOptions = computed(() => {
    const options: (OrderType | Disabled)[] =
      organizationsStore.availableTestTypes[organization.value.type]
        .filter((it) => it.testType === testType)
        .map((it) => it.orderType);
    options.push('disabled');
    return options;
  });

  const enableTestTypeTopic = computed(() =>
    isDoa.value
      ? t('admin_board.table.edit_row.enable_doa')
      : t('admin_board.table.edit_row.enable_peth'),
  );

  const enabledSamplingKitsKey = computed(() =>
    isDoa.value ? 'enabledDoaSamplingKits' : 'enabledPethSamplingKits',
  );
  const enabledOrderTypeKey = computed(() =>
    isDoa.value ? 'enabledDoaOrderType' : 'enabledPethOrderType',
  );

  const orderType = ref<OrderType | Disabled>(
    initialOrganization[enabledOrderTypeKey.value] || 'disabled',
  );

  watch(orderType, (val) => {
    let newOrderType = undefined;
    if (val === 'disabled') {
      resetSamplingKits();
    } else {
      newOrderType = val;
    }
    organization.value[enabledOrderTypeKey.value] = newOrderType;
  });

  const orderTypeChangeMessage = computed(() => {
    return isOrderTypeChanged.value
      ? `\n${getOrderTypeLabel(orderType.value, testType)}`
      : '';
  });

  const samplingKitChanges = computed(() => {
    const currentSamplingKits =
      organization.value[enabledSamplingKitsKey.value];
    const initialSamplingKits =
      initialOrganization[enabledSamplingKitsKey.value];
    const addedSamplingKits = currentSamplingKits.filter(
      (it) => !initialSamplingKits.includes(it),
    );
    const removedSamplingKits = initialSamplingKits.filter(
      (it) => !currentSamplingKits.includes(it),
    );
    return { addedSamplingKits, removedSamplingKits };
  });

  const samplingKitEnableMessage = computed(() => {
    if (
      !organization.value[enabledOrderTypeKey.value] ||
      !samplingKitChanges.value.addedSamplingKits.length
    ) {
      return '';
    }
    const samplingKits = samplingKitChanges.value.addedSamplingKits
      .map((it) => t(`samplingKits.${it}`))
      .join(', ');

    return `\n${t('admin_board.table.edit_row.enable_sampling_kits_prompt', {
      samplingKits,
    })}`;
  });

  const samplingKitDisableMessage = computed(() => {
    if (
      !organization.value[enabledOrderTypeKey.value] ||
      !samplingKitChanges.value.removedSamplingKits.length
    ) {
      return '';
    }
    const samplingKits = samplingKitChanges.value.removedSamplingKits
      .map((it) => t(`samplingKits.${it}`))
      .join(', ');
    return `\n${t('admin_board.table.edit_row.disable_sampling_kits_prompt', {
      samplingKits,
    })}`;
  });

  const confirmationMessage = computed(() => {
    return (
      `${t('admin_board.table.edit_row.confirmation_1', {
        organization: organization.value.name,
      })}\n` +
      orderTypeChangeMessage.value +
      samplingKitEnableMessage.value +
      samplingKitDisableMessage.value +
      `\n\n${t('admin_board.table.edit_row.confirmation_2')}`
    );
  });

  const resetSamplingKits = () => {
    organization.value[enabledSamplingKitsKey.value] = [
      ...initialOrganization[enabledSamplingKitsKey.value],
    ]; // INFO: clone the array
  };

  const isSamplingKitChanged = computed(
    () =>
      samplingKitChanges.value.addedSamplingKits.length ||
      samplingKitChanges.value.removedSamplingKits.length,
  );

  const isOrderTypeChanged = computed(
    () =>
      organization.value[enabledOrderTypeKey.value] !==
      initialOrganization[enabledOrderTypeKey.value],
  );

  const isProceedable = computed(() => {
    if (orderType.value === 'disabled') {
      return isOrderTypeChanged.value;
    }

    const result =
      organization.value[enabledSamplingKitsKey.value].length &&
      (isOrderTypeChanged.value || isSamplingKitChanged.value);

    return result;
  });

  const getOrderTypeLabel = (
    option: OrderType | Disabled,
    optionalTestType?: TestType,
  ) => {
    if (option === 'disabled') {
      return t('admin_board.table.edit_row.disable', {
        testType: optionalTestType,
      });
    }

    // INFO: We check the length of the orderTypeOptions to determine if the non-CoC option should be enabled.
    if (orderTypeOptions.value?.length > 2 && option === 'non-CoC') {
      return t('admin_board.table.edit_row.enable_non_coc', {
        testType: optionalTestType,
      });
    }

    return t('admin_board.table.edit_row.enable', {
      testType: optionalTestType,
    });
  };

  const getDto = () => {
    const enabledTestTypes = [];
    if (organization.value.enabledDoaOrderType) {
      enabledTestTypes.push({
        testType: TestType.DoA,
        orderType: organization.value.enabledDoaOrderType,
      });
    }

    if (organization.value.enabledPethOrderType) {
      enabledTestTypes.push({
        testType: TestType.PEth,
        orderType: organization.value.enabledPethOrderType,
      });
    }

    return {
      organizationKey: organization.value.key,
      organizationType: organization.value.type,
      enabledTestTypes,
      enabledSamplingKits: [
        ...organization.value.enabledDoaSamplingKits,
        ...organization.value.enabledPethSamplingKits,
      ],
    };
  };

  const handleSamplingKitChange = ({
    isEnabled,
    samplingKit,
  }: SamplingKitArgs) => {
    const wasSamplingKitAlreadyEnabled = organization.value[
      enabledSamplingKitsKey.value
    ].find((it) => it === samplingKit);

    if (isEnabled && !wasSamplingKitAlreadyEnabled) {
      organization.value[enabledSamplingKitsKey.value].push(samplingKit);
      return;
    }

    organization.value[enabledSamplingKitsKey.value] = organization.value[
      enabledSamplingKitsKey.value
    ].filter((it) => it !== samplingKit);
  };

  return {
    orderType,
    orderTypeOptions,
    enableTestTypeTopic,
    activityDefinitionOptions,
    organization,
    isDoa,
    isProceedable,
    confirmationMessage,
    getOrderTypeLabel,
    handleSamplingKitChange,
    getDto,
  };
};
