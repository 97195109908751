<template>
  <div
    ref="wrapper"
    class="flex items-center w-0 min-w-full h-[38px]"
    @focusout="focusLost"
  >
    <template v-for="(input, index) in computedInputs" :key="input">
      <input
        :data-test-id="`input-mask-${index}`"
        class="focus:border-careos-lavendel focus:border-[1px] text-center rounded font-light focus-visible:outline-none border border-gray-400 box-border h-full px-2"
        :style="{
          width: (input.length / computedInputs.join('').length) * 100 + '%',
        }"
        type="text"
        inputmode="numeric"
        :maxlength="input.length"
        :placeholder="input"
        @input="handleInput(input, $event, index)"
      />
      <div
        v-if="index !== computedInputs.length - 1"
        class="flex border-none items-center justify-center mx-2 w-min focus-visible:outline-none"
      >
        {{ separator }}
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { FormKitFrameworkContext } from '@formkit/core';
import { computed, onMounted, ref } from 'vue';

const isHtmlElement = (val: unknown): val is HTMLElement =>
  val instanceof HTMLElement;

const wrapper = ref<HTMLDivElement>();
const props = defineProps<{ context: FormKitFrameworkContext }>();

const createProp = (propName: string, defaultValue?: string): string => {
  if (typeof props.context.attrs[propName] === 'string') {
    return props.context.attrs[propName];
  }
  return defaultValue ?? '';
};
const mask = computed(() => createProp('mask'));
const separator = computed(() => createProp('separator', '-'));
const computedInputs = computed(() => mask.value.split(separator.value));
// Set value on mount if a value is provided with v-model
onMounted(() => {
  const initialValue = props.context.value;
  if (!initialValue || typeof initialValue !== 'string') {
    return;
  }
  const inputs = wrapper.value?.querySelectorAll('input');
  inputs?.forEach((input, index) => {
    // eslint-disable-next-line
    input.value = initialValue.split(separator.value)[index];
  });
});

const focusLost = (event: FocusEvent) => {
  if (!isHtmlElement(event.relatedTarget)) {
    props.context.handlers.blur();
    return;
  }
  if (!isHtmlElement(event.target)) {
    return;
  }
  const hasSameParent = event.relatedTarget.parentNode?.isEqualNode(
    event.target.parentNode,
  );
  if (hasSameParent) {
    return;
  }
  props.context.handlers.blur();
};

const handleInput = (placeholder: string, event: Event, index: number) => {
  const target = event.target as HTMLInputElement;
  const inputs = target.parentElement?.querySelectorAll('input');

  if (!inputs) return;
  const values: string[] = [];

  inputs.forEach((input) => values.push(input.value));
  values.filter((value) => Boolean(value));

  props.context.node.input(values.join(separator.value));
  // If we are at last index don't focus the next
  if (index === inputs.length - 1) return;
  if (placeholder.length === target.value.length) {
    inputs[index + 1].focus();
  }
};
</script>
