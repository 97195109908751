import { z } from 'zod';

export const ComparatorSchema = z.enum(['<', '<=', '>=', '>']);
/**
 * [Less than] < The actual value is less than the given value.
 *
 * [Less or Equal to] <=
 * The actual value is less than or equal to the given value.
 *
 * [Greater or Equal to] >=
 * The actual value is greater than or equal to the given value.
 *
 * [Greater than] >
 * The actual value is greater than the given value.
 */
export type Comparator = z.infer<typeof ComparatorSchema>;

export const UnitSchema = z.union([z.string(), z.literal('unknown')]);
/**
 * Unit in which measurement is made. If a unit is not known, it is defined as 'unknown'
 */
export type Unit = z.infer<typeof UnitSchema>;

export const QuantitySchema = z.object({
  /**
   * Numerical value.
   */
  value: z.number(),

  /**
   * How to understand the value. {@link Comparator}.
   * If the comparator is `undefined` it is assumed that the value is equal
   * to the actual value.
   */
  comparator: ComparatorSchema.optional(),

  /**
   * To which level the value is precise.
   * If the value is 1.2 and precision is 4 the resulting value is 1.200.
   * If the precision is `undefined` it is assumed to be the precision of the value.
   */
  significantDigits: z.number().optional(),

  /**
   * Textual representation of the quantity
   */
  text: z.string().optional(),

  /**
   * Unit in which measurement is made. If a unit is not known, it is defined as 'unknown'
   */
  unit: UnitSchema,
});

/**
 * A measured or measurable amount.
 */
export type Quantity = z.infer<typeof QuantitySchema>;
