<template>
  <div class="justify-content-between flex">
    <DownloadDoaResultButton
      type="button"
      class="h-8 w-full"
      :label="resultCount"
      :loading="isLoading"
      @click.capture="toggle"
    />
    <OverlayPanel ref="overlayPanelRef">
      <ResultReportDownloadList
        :chiral-status="chiralStatus"
        :is-loading="isLoading"
        @on-click-download="$emit('on-click-download', $event)"
      />
    </OverlayPanel>
  </div>
</template>

<script setup lang="ts">
import OverlayPanel from 'primevue/overlaypanel';
import ResultReportDownloadList from './ResultReportDownloadList.vue';

import { useI18n } from 'vue-i18n';
import { getResultsStatus } from '../helpers/get-results-status';
import { computed, ref } from 'vue';
import DownloadDoaResultButton from './DownloadDoaResultButton.vue';
import { ExtraResultStatus } from '@careos/toxicology-types';

const { t } = useI18n();

defineEmits(['on-click-download']);
const props = defineProps<{
  chiralStatus: ExtraResultStatus;
  isLoading: boolean;
}>();

const overlayPanelRef = ref();

const toggle = (event: Event) => {
  overlayPanelRef.value.toggle(event);
};

const resultCount = computed(() => {
  const resultsStatus = getResultsStatus(props.chiralStatus);
  return `${t('results.pos')} ${resultsStatus}`;
});
</script>
