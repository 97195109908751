<script setup lang="ts">
import type { FailedResponseDto } from 'careos-bankid-adapter';
import { type Locale } from '../../composables/i18n';
import { useI18n } from '../../composables/i18n';
import { HintCode } from 'careos-bankid-adapter';

defineEmits<{
  onCancel: [];
  onTryAgain: [];
}>();

const props = defineProps<{
  failedData: FailedResponseDto;
  locale: Locale;
}>();
const { t } = useI18n(props.locale);
</script>

<template>
  <div class="flex flex-col gap-y-5 text-lg h-full justify-between">
    <p>
      {{
        t(`hintcode-qr-${failedData.hintCode}`) ||
        t(`hintcode-qr-failed-${HintCode.unknown}`)
      }}
    </p>
    <div class="flex flex-col gap-y-5 h-full justify-self-end">
      <button
        class="bg-black px-8 py-4 text-white"
        @click="$emit('onTryAgain')"
      >
        {{ t('try-again') }}
      </button>
      <button @click="$emit('onCancel')">
        {{ t('cancel') }}
      </button>
    </div>
  </div>
</template>
