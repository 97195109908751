import type {
  CheckResponseDto,
  SignRequestDto,
  SignResponseDto,
} from 'careos-bankid-adapter';
import { ofetch } from 'ofetch';
import type { Ref } from 'vue';
import { isProd, isStaging } from '../utils/deployment-environment';

export const bankidBaseUrl = isProd
  ? 'https://api.careos.io/v1/bankid-adapter'
  : isStaging
  ? 'https://staging-api.careos.io/v1/bankid-adapter'
  : 'http://localhost:5012/v1/bankid-adapter';

export const initApi = (accessToken: Ref) => {
  const baseFetch = ofetch.create({
    retry: 0,
    baseURL: bankidBaseUrl,
    async onResponse({ response }) {
      if ('errorCode' in response._data) {
        throw response._data;
      }
    },
    async onRequest({ options }) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${accessToken.value}`,
      };
    },
  });

  const api = {
    sign: (body: SignRequestDto) =>
      baseFetch<SignResponseDto>(`/signing/sign`, {
        method: 'POST',
        body,
      }),
    check: (orderRef: string) =>
      baseFetch<CheckResponseDto>(`/signing/check/${orderRef}`),
    cancel: (orderRef: string) =>
      baseFetch(`/signing/cancel/${orderRef}`, { method: 'DELETE' }),
  };
  return api;
};
