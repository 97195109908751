import { z } from 'zod';
import { CharacterEncodingSchema } from './character-encoding.type';
import { LanguageSchema } from './language.type';
import { MimeTypeSchema } from './mime-type.type';

export const AttachmentSchema = z.object({
  /**
   * Mime type of the content, with charset etc.
   */
  contentType: MimeTypeSchema,
  /**
   * Human language of the content (BCP-47)
   * https://build.fhir.org/valueset-languages.html
   */
  language: LanguageSchema,
  /**
   * The filename of the result file
   */
  filename: z.string(),
  /**
   * The file extension including the "."
   */
  fileExtension: z.string(),
  /**
   * Hash of the data (sha-1, base64ed)
   */
  hash: z.string(),
  /**
   * The contents of the result file
   */
  data: z.object({
    /**
     * The string encoding used for the data.
     */
    encoding: CharacterEncodingSchema,
    /**
     * The raw data string
     */
    raw: z.string(),
  }),
});

/**
 * This type is for containing or referencing attachments - additional data
 * content defined in other formats. The most common use of this type is to
 * include images or reports in some report format such as PDF.
 * However, it can be used for any data that has a MIME type.
 * Inspired by: https://build.fhir.org/datatypes.html#Attachment
 */
export type Attachment = z.infer<typeof AttachmentSchema>;
