<template>
  <OrderViewWrapper>
    <OrderViewCard
      data-test-id="specimen-type"
      :title="t('specimenType.title')"
      :forward-label="t('general.next_step')"
      :disable-forwards="!selectedSpecimen"
      :show-swedac-logo="store.selectedTestType === 'PEth'"
      @action-left="previousStep"
      @action-right="nextStep"
    >
      <div :class="{ 'min-h-[135px]': !options.length }">
        <div v-for="specimen in options" :key="specimen.value" class="p-2">
          <RadioButton
            :label="specimen.label"
            :selected="specimen.value === store.selectedSpecimen"
            @click="selectSpecimen(specimen.value)"
          />
        </div>
      </div>
    </OrderViewCard>
  </OrderViewWrapper>
</template>
<script setup lang="ts">
import { router } from '@/router';
import { useOrderViewStore } from '@/views/order-view/store/order-view-store';
import { Ref, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import RadioButton from '../../components/RadioButton.vue';

import { useNetworkRequest } from '@/composables/useNetworkRequest';
import { ToxicologyActivityIdentifier } from '@careos/identifiers';
import OrderViewCard from '../../components/OrderViewCard.vue';
import OrderViewWrapper from '../../components/OrderViewWrapper.vue';
import { useSamplingKit } from '@/composables/useSamplingKit';
import { useSamplingKitStore } from '@/stores/order/samplingKitStore';

const { t } = useI18n();
const store = useOrderViewStore();
const samplingKitStore = useSamplingKitStore();
const selectedSpecimen = ref(store.selectedSpecimen);
const options: Ref<{ label: string; value: ToxicologyActivityIdentifier }[]> =
  ref([]);

const {
  fetchAllSamplingKitOptions,
  getDefaultSamplingKit,
  handleSamplingKitChange,
  getSpecimenTypeOptions,
} = useSamplingKit();

const clearSelectedAdditionalSubstances = () => {
  store.selectedAdditionalSubstances = [];
};

const previousStep = () => {
  router.go(-1);
};

const nextStep = () => {
  if (!store.selectedSpecimen) return;
  if (store.selectedSpecimen === 'DOA_URINE_SAMPLING') {
    router.push({
      path: '/order/substances',
    });
    return;
  }
  store.isChiralOrdered = false;
  const defaultSamplingKit = getDefaultSamplingKit(store.selectedSpecimen);

  if (!defaultSamplingKit) {
    router.push({
      path: '/order/sampling-kit',
    });
    return;
  }
  handleSamplingKitChange(defaultSamplingKit);
  router.push({
    path: '/order/substances',
  });
};

const selectSpecimen = (specimen: ToxicologyActivityIdentifier) => {
  if (store.selectedSpecimen !== specimen) {
    clearSelectedAdditionalSubstances();
    samplingKitStore.$reset();
    store.selectedPanelSize = 'L';
  }
  store.selectedSpecimen = specimen;
  nextStep();
};

onBeforeMount(async () => {
  await useNetworkRequest(() => store.fetchSpecimenTypes()).exec();
  await fetchAllSamplingKitOptions();
  options.value = getSpecimenTypeOptions();
});
</script>
