<template>
  <CareosTable
    v-model:filters="filters"
    :loading="!!loading"
    :value="sessions"
    :paginator="true"
    :rows="10"
    :rows-per-page-options="[5, 10, 15]"
    paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :filter="storedStateSessionsTable"
    :state-key="`${sessionsStorageKey}_${sessionsTableID}`"
    filter-display="menu"
    :sort-field="'samplingDate'"
    :responsive-layout="responsiveLayout"
    :sort-order="-1"
    :state="stateSessionsTable"
  >
    <Column
      field="samplingDate"
      filter-field="samplingDate"
      sortable
      :show-filter-match-modes="false"
      :header="t('sessions.table.columns.sampling_date')"
      data-type="date"
      ><template #filter="{ filterModel, filterCallback }">
        <Calendar
          v-model="filterModel.value"
          :inline="true"
          :show-week="true"
          :max-date="new Date()"
          @keydown.enter="filterCallback()"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('table.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          :label="t('table.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        />
      </template>

      <template #body="slotProps">{{
        dayjs(slotProps.data.samplingDate).format('YYYY-MM-DD')
      }}</template></Column
    >

    <Column
      field="sessionId"
      sortable
      :show-filter-match-modes="false"
      :header="t('sessions.table.columns.session_id')"
      data-type="string"
      ><template #filter="{ filterModel, filterCallback }">
        <InputText
          v-model="filterModel.value"
          type="text"
          class="w-full rounded border border-gray-300 p-2"
          :placeholder="t('sessions.table.columns.session_id')"
          @input="filterCallback()"
          @keypress.enter="filterApply && filterApply.$el.click()"
        />
      </template>
      <template #filterclear="{ filterCallback }">
        <CareosSecondaryButton
          :label="t('table.clear')"
          class="mr-5"
          @click="filterCallback()"
        />
      </template>
      <template #filterapply="{ filterCallback }">
        <CareosButton
          ref="filterApply"
          :label="t('table.apply')"
          type="button"
          class="ml-5"
          @click="filterCallback()"
        /> </template
    ></Column>

    <Column field="donorName" :show-filter-match-modes="false">
      <template #header>
        {{ t('sessions.table.columns.employee_name') }}
        <div
          v-tooltip.right="{
            value: t('sessions.table.employee_name_tooltip'),
          }"
        >
          <PrimeIcon icon="INFO_CIRCLE" size="XS" class="m-2" />
        </div>
      </template>
      <template #body="{ data }">
        <div
          v-tooltip.top="{
            value: getEmployeeTooltip(data.employeeName),
          }"
        >
          {{ data.employeeName }}
        </div>
      </template>
    </Column>

    <Column
      field="testTypes"
      :show-filter-match-modes="false"
      :header="t('sessions.table.columns.test_type')"
      data-type="text"
      style="min-width: 14.2rem"
    >
      <template #body="{ data }">
        {{ data.testTypes[0] }}
      </template>
    </Column>

    <Column
      field="reasonForTesting"
      :show-filter-match-modes="false"
      :header="t('sessions.table.columns.reason')"
      data-type="text"
      style="min-width: 14.2rem"
    >
      <template #body="{ data }">
        {{ t(`sessions.table.reasonForTesting.${data.reasonForTesting}`) }}
      </template>
    </Column>

    <Column
      field="status"
      :show-filter-match-modes="false"
      :header="t('sessions.table.columns.status')"
      data-type="text"
      style="min-width: 11.2rem"
    >
      <template #body="{ data }">
        <div :class="statusBadgeClass(data.status)">
          {{ t(`sessions.table.session_status.${data.status}`) }}
        </div>
      </template>
    </Column>
    <Column field="Actions">
      <template #header>
        {{ t('sessions.table.columns.actions') }}
      </template>
      <template #body="{ data }">
        <SessionActionsButtons
          :reason="data.reasonForTesting"
          :status="data.status"
          @session-action="
            (action: SessionActions) =>
              handleSessionAction(action, data.sessionId)
          "
        />
      </template>
    </Column>
  </CareosTable>
  <Dialog v-model:visible="displayRandomizerModal" modal closable>
    <RandomizerModalContent />
  </Dialog>

  <Dialog v-model:visible="displayCloseSessionModal" modal closable>
    <CloseSessionModalContent
      :session-id="closingSessionId"
      @close-session="closeSession"
    />
  </Dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import {
  ref,
  ComponentPublicInstance,
  watch,
  onBeforeMount,
  onMounted,
  onUnmounted,
} from 'vue';
import dayjs from 'dayjs';
import { usePrimeVue } from 'primevue/config';
import { PrimeIcon } from 'careos-vue-components';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import CareosButton from '@/components/CareosButton.vue';
import CareosSecondaryButton from '@/components/CareosSecondaryButton.vue';
import CareosTable from '@/components/CareosTable.vue';
import {
  SamplingSession,
  FinishSamplingSessionRequestDto,
} from '@careos/toxicology-types';
import { statusBadgeClass } from '../models/status-badge-class';
import SessionActionsButtons from './SessionActionsButtons.vue';
import { SessionActions } from '../constants/session-actions';
import { useSessionActions } from '@/composables/useSessionActions';
import RandomizerModalContent from './RandomizerModalContent.vue';
import CloseSessionModalContent from './CloseSessionModalContent.vue';
import { useSessionFilters } from '@/composables/useSessionFilters';
import axios from 'axios';
import { ToxiHttpClient } from '@/api/toxicology-client';
import { useToast } from 'primevue/usetoast';

const {
  handleSessionAction,
  displayRandomizerModal,
  displayCloseSessionModal,
  closingSessionId,
} = useSessionActions();

defineProps<{
  sessions: SamplingSession[];
  loading?: boolean;
}>();

const { filters } = useSessionFilters();
const primeVue = usePrimeVue();
const { t, locale } = useI18n();
const toast = useToast();
const filterApply = ref<ComponentPublicInstance | null>(null);

const sessionsStorageKey = 'dataTableState';
let storedStateSessionsTable: string | null = '';
const state = ref({});
const sessionsTableID = 'sessionsTable';

const responsiveLayout = ref<'stack' | 'scroll'>('stack');

const getEmployeeTooltip = (employee: string) => {
  if (!employee) {
    return '';
  }
  const employeePersonNumber = '20121212-1212';
  const employeePhoneNumber = '46731234567';
  return `Employee: ${employee}
    Person number: ${employeePersonNumber}
    Phone number: ${employeePhoneNumber}
  `;
};

const onWidthChange = () => {
  const widthMeasurementTypeOne = document.documentElement.clientWidth || 0;
  const widthMeasurementTypeTwo = window.innerWidth || 0;
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  Math.max(widthMeasurementTypeOne, widthMeasurementTypeTwo) > 1260
    ? (responsiveLayout.value = 'scroll')
    : (responsiveLayout.value = 'stack');
};

const closeSession = async (notes: string) => {
  if (!closingSessionId.value) {
    return;
  }
  const dto: FinishSamplingSessionRequestDto = {
    sessionId: closingSessionId.value,
    notes,
  };

  try {
    await ToxiHttpClient.post('/sampling-session/finish-sampling-session', dto);
    toast.add({
      severity: 'success',
      summary: t('sessions.close_session.modal.session_closed_toast_msg'),
      life: 5000,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.add({
        severity: 'error',
        summary: `${t('general.error_occurred')}. ${t(
          'general.error_is_reported_message',
        )}`,
        life: 5000,
      });
    }
  }
  displayCloseSessionModal.value = false;
};

watch(state, (newState) => {
  sessionStorage.setItem(sessionsStorageKey, JSON.stringify(newState));
});

onBeforeMount(() => {
  sessionStorage.removeItem(sessionsStorageKey);
});

onMounted(() => {
  window.addEventListener('resize', onWidthChange);
  onWidthChange();
  storedStateSessionsTable = sessionStorage.getItem(
    `${sessionsStorageKey}_${sessionsTableID}`,
  );
  if (storedStateSessionsTable) {
    state.value = JSON.parse(storedStateSessionsTable);
  }
});

const stateSessionsTable = ref(
  storedStateSessionsTable ? JSON.parse(storedStateSessionsTable) : {},
);

onUnmounted(() => window.removeEventListener('resize', onWidthChange));

const updatePrimeVueLocale = () => {
  if (!primeVue.config.locale) {
    throw new Error('PrimeVue locale is not defined');
  }
  primeVue.config.locale = {
    ...primeVue.config.locale,
    monthNames: [
      t('sessions.table.filters.january'),
      t('sessions.table.filters.february'),
      t('sessions.table.filters.march'),
      t('sessions.table.filters.april'),
      t('sessions.table.filters.may'),
      t('sessions.table.filters.june'),
      t('sessions.table.filters.july'),
      t('sessions.table.filters.august'),
      t('sessions.table.filters.september'),
      t('sessions.table.filters.october'),
      t('sessions.table.filters.november'),
      t('sessions.table.filters.december'),
    ],
    dayNamesMin: [
      t('sessions.table.filters.sunday'),
      t('sessions.table.filters.monday'),
      t('sessions.table.filters.tuesday'),
      t('sessions.table.filters.wednesday'),
      t('sessions.table.filters.thursday'),
      t('sessions.table.filters.friday'),
      t('sessions.table.filters.saturday'),
    ],
  };
};

watch(
  locale,
  () => {
    updatePrimeVueLocale();
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
.status-badge {
  padding: 0.25em 0.6em;
  border-radius: 16px;
  font-size: 0.75rem;
  color: white;
  text-align: center;
  display: inline-block;
  &-closed {
    background-color: #def0d9;
    color: #007a3d;
  }

  &-open {
    background-color: #fff0c3;
    color: #7e6824;
  }
}
</style>
