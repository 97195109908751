import { z } from 'zod';

export const DoaActivityDefinitionIdentifierSchema = z.enum([
  'DOA_URINE_SAMPLING',
  'DOA_SALIVA_SAMPLING',
]);
export const DoaActivityDefinitionIdentifier =
  DoaActivityDefinitionIdentifierSchema.Enum;
export type DoaActivityDefinitionIdentifier = z.infer<
  typeof DoaActivityDefinitionIdentifierSchema
>;

export const PethActivityDefinitionIdentifierSchema = z.enum([
  'PETH_DRIED_BLOOD_SPOTS',
]);
export const PethActivityDefinitionIdentifier =
  PethActivityDefinitionIdentifierSchema.Enum;
export type PethActivityDefinitionIdentifier = z.infer<
  typeof PethActivityDefinitionIdentifierSchema
>;

export const ToxicologyActivityIdentifierSchema = z.enum([
  ...DoaActivityDefinitionIdentifierSchema.options,
  ...PethActivityDefinitionIdentifierSchema.options,
]);
export const ToxicologyActivityIdentifier =
  ToxicologyActivityIdentifierSchema.Enum;
export type ToxicologyActivityIdentifier = z.infer<
  typeof ToxicologyActivityIdentifierSchema
>;

export const ActivityDefinitionIdentifierSchema = z.enum([
  ...ToxicologyActivityIdentifierSchema.options,
  'COV_VESTNPH_THROAT',
  'CTR_URINE',
  'CTR_VAGINAL_SWAB',
  'DOA_BREATH_SAMPLING',
]);
export const ActivityDefinitionIdentifier =
  ActivityDefinitionIdentifierSchema.Enum;

export type ActivityDefinitionIdentifier = z.infer<
  typeof ActivityDefinitionIdentifierSchema
>;

export const isActivityDefinitionIdentifier = (
  value: unknown,
): value is ActivityDefinitionIdentifier =>
  ActivityDefinitionIdentifierSchema.safeParse(value).success;

export const isDoaActivityIdentifier = (
  value: unknown,
): value is DoaActivityDefinitionIdentifier =>
  DoaActivityDefinitionIdentifierSchema.safeParse(value).success;

export const isPethActivityIdentifier = (
  value: unknown,
): value is PethActivityDefinitionIdentifier =>
  PethActivityDefinitionIdentifierSchema.safeParse(value).success;

export const isToxicologyActivityIdentifier = (
  value: unknown,
): value is ToxicologyActivityIdentifier =>
  ToxicologyActivityIdentifierSchema.safeParse(value).success;
