<template>
  <RequisitionWrapper v-if="result">
    <RequisitionCard
      data-test-id="requisition-created"
      class="max-w-5xl pb-4 md:pb-8"
    >
      <SvgIcon
        name="vialcirclecheck"
        class="inline-block h-28 text-careos-purple"
      />
      <template v-if="isCoc">
        <p class="mt-5 text-left font-semibold">
          {{ t('created.requisition_password') }}
        </p>
        <p
          class="rounded bg-careos-purple-light text-xl font-semibold"
          data-test-id="signed-consent-password"
        >
          {{ result.patientPassCode }}
        </p>
      </template>

      <div class="mt-8 flex flex-col gap-4 text-left">
        <DetailsSummary
          v-if="personalDetailsItems"
          :details-items="personalDetailsItems"
          size="wide"
        />
        <DetailsSummary
          v-if="organizationDetailItems"
          :details-items="organizationDetailItems"
          size="wide"
        />

        <RequisitionSection
          v-if="result.testType === 'DoA'"
          :heading="panelTitle(result.panel.size)"
        >
          <SubstanceList
            :substances="result.panel.panelSubstanceObservations"
          />
        </RequisitionSection>

        <RequisitionSection
          v-if="result.testType === 'PEth'"
          :heading="t('summary.alcohol_marker')"
        >
          {{ t('substances.names.PHOSPHATIDYLETHANOL') }}
        </RequisitionSection>

        <RequisitionSection
          v-if="result.panel.additionalObservations.length"
          :heading="t('summary.additional_substance')"
        >
          <SubstanceList :substances="result.panel.additionalObservations" />
        </RequisitionSection>

        <RequisitionSection
          v-if="result.panel.isChiralOrdered"
          :heading="t('summary.chiral_header')"
        >
          {{ t('summary.chiral_text') }}
        </RequisitionSection>

        <RequisitionSection :heading="t('comment.title')">
          {{ result.collectorComment }}
        </RequisitionSection>
      </div>

      <RequisitionConsentSection
        v-if="isCoc"
        class="mb-4 mt-8"
        is-signed
        :consent-text="
          t(
            `consent_form.${
              ActivityDefinitionToTestType[result.specimenType]
            }_consent_text`,
          )
        "
      >
        <template
          v-if="
            result.patient &&
            signature &&
            signature.signatureType === SignatureType.HANDWRITTEN
          "
          #signature
        >
          <Signature
            :signature="signature"
            :patient-name="`${result.patient.firstName} ${result.patient.lastName}`"
          />
        </template>
      </RequisitionConsentSection>
    </RequisitionCard>
  </RequisitionWrapper>
</template>

<script setup lang="ts">
import DetailsSummary from '@/components/Requisition/DetailsSummary.vue';
import RequisitionCard from '@/components/Requisition/RequisitionCard.vue';
import RequisitionConsentSection from '@/components/Requisition/RequisitionConsentSection.vue';
import RequisitionSection from '@/components/Requisition/RequisitionSection.vue';
import RequisitionWrapper from '@/components/Requisition/RequisitionWrapper.vue';
import SubstanceList from '@/components/Requisition/SubstanceList.vue';
import Signature from '@/components/Signature.vue';
import { PersonalDetailsItem } from '@/models/personal-details-item';

import { ToxiHttpClient } from '@/api/toxicology-client';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import {
  PanelSize,
  ReasonsForTestingEnum,
  RequisitionDetailDto,
  mapSignatureData,
  SignatureType,
  SigningDisplay,
} from '@careos/toxicology-types';

import SvgIcon from '@/components/SvgIcon.vue';
import { ActivityDefinitionToTestType } from '@careos/organization-api-types';

const { t } = useI18n();
const route = useRoute();

const result = ref<RequisitionDetailDto>();
const isCoc = ref<boolean>();
const isLoading = ref(false);

const fetchResults = async () => {
  isLoading.value = true;
  const requisitionId = `${route.params.requisitionId}`;
  const res = await ToxiHttpClient.get<RequisitionDetailDto>(
    `/requisitions/created-requisition/${requisitionId}`,
  );
  isLoading.value = false;
  return res.data;
};

const getReasonToTestCodeValue = (reasonToTest: string): string => {
  if (ReasonsForTestingEnum.safeParse(reasonToTest).success) {
    return t(`reasons_for_testing.reason_options.${reasonToTest}`);
  }
  return reasonToTest;
};

onMounted(async () => {
  const response = await fetchResults();
  result.value = response;
  isCoc.value = result.value?.orderType === 'CoC';
});

const personalDetailsItems = computed(() => {
  if (
    !result.value ||
    !result.value.patient ||
    !result.value.specimenType ||
    !result.value.barcode ||
    !result.value.collectorComment
  ) {
    return;
  }
  const specimen = `specimenType.types.${result.value.specimenType}`;
  const samplingKit = result.value.samplingKit
    ? ` - ${t(`samplingKits.${result.value.samplingKit}`)}`
    : '';

  const summaryList: PersonalDetailsItem[] = [
    {
      title: t('personalDetails.firstName'),
      value: result.value.patient.firstName,
    },
    {
      title: t('personalDetails.lastName'),
      value: result.value.patient.lastName,
    },
    {
      title: t('personalDetails.phoneNumber'),
      value: result.value.patient.phoneNumber,
    },
    {
      title: t('identifier.identifier'),
      value: result.value.patient.personalIdentifier,
    },
    {
      title: t('specimenType.specimen_type'),
      value: t(specimen) + samplingKit,
    },
    { title: t('barcode.barcode'), value: result.value.barcode },
    {
      title: t('personalDetails.attester.label'),
      value: result.value.attester?.name,
    },
    {
      title: t('personalDetails.attester.personalNumber'),
      value: result.value.attester?.personalIdentifier,
    },
    {
      title: t('reasons_for_testing.title'),
      value: getReasonToTestCodeValue(result.value.reasonForTesting),
    },
  ];
  return summaryList;
});

const organizationDetailItems = computed(() => {
  if (!result.value || !result.value.barcode) {
    return;
  }

  const summaryList: PersonalDetailsItem[] = [
    {
      title: t('created.organization'),
      value: result.value.careProvider,
    },
    {
      title: t('created.collector'),
      value: result.value.collector.name,
    },
    {
      title: t('created.collector_email'),
      value: result.value.collector.email,
    },
  ];

  if (result.value.organizationType === 'workplace') {
    summaryList.push(
      {
        title: t('employer.employer'),
        value: result.value.employer?.display,
      },
      {
        title: t('employer.subdivision'),
        value: result.value.employer?.subdivision,
      },
      {
        title: t('employer.subcontractor'),
        value: result.value.employer?.subcontractor,
      },
    );
  }
  if (result.value.organizationType === 'treatment_center') {
    summaryList.push({
      title: t('treatmentCenter.treatmentCenter'),
      value: result.value.treatmentCenter?.display,
    });
  }
  summaryList.push();
  return summaryList;
});

const signature = computed<SigningDisplay | undefined>(() => {
  if (!result.value?.signature) return;
  return mapSignatureData(result.value.signature);
});

const panelTitle = (size: PanelSize) => {
  let title = '';
  if (size !== 'SG') {
    title = `${t('substances.panelTitle')}`;
  }
  return `${title} ${t(`substances.${size}`)}`;
};
</script>
