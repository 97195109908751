import { messages as enUS } from './en-US';

export const messages: typeof enUS = {
  system: {
    leave_warning:
      'Är du säker på att du vill lämna sidan? Du har osparade ändringar!',
    order_summary_leave_warning: 'Du kommer behöva signera igen om du backar',
  },
  footer: {
    appVersion: 'Version',
  },
  personalDetails: {
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    personalNumber: 'Personnummer',
    dateOfBirth: 'Födelsedag',
    phoneNumber: 'Telefonnummer',
    phoneNumberOptional: 'Telefonnummer (valfritt)',
    yes: 'Ja',
    no: 'Nej',
    has_personal_id: 'Har personen ett svenskt personnummer? ',
    validation: {
      charactersNotLatin: 'Vänligen ange enbart latinska bokstäver',
      personalNumber: 'Vänligen ange personnumret i korrekt format',
      phoneNumberNotValid: 'Vänligen ange telefonnummer i korrekt format',
    },
    title: 'Fyll i personlig information',
    patient_identification: 'Identifikation',
    patient_identity_verified:
      'Jag som provtagare bekräftar att personens identitet har verifierats',
    attester_is_needed: 'Vidimera person',
    attester: {
      label: 'Vidimering',
      firstName: 'Förnamn för vidimering',
      lastName: 'Efternamn för vidimering',
      personalNumber: 'Personnummer för vidimering',
    },
  },
  reasons_for_testing: {
    title: 'Anledning till provtagning',
    reason_options: {
      random_selection: 'Slumpmässig',
      new_employee: 'Nyanställning',
      health_check: 'Hälsokontroll',
      rehabilitation: 'Rehabilitering',
      incident_accident: 'Tillbud/olycka',
      other: {
        label: 'Annan',
        placeholder: 'Fyll i anledning',
      },
    },
  },
  requisitionType: {
    title: 'Typ av provtagning',
    typeOptions: {
      workplace: 'Arbetsplats',
      treatment_center: 'Rehabiliteringscenter',
    },
  },
  samplingKits: {
    title: 'Välj specifik metod',
    GREINER: 'Greiner',
    INTERCEPT: 'Intercept',
    DRIED_BLOOD_SPOTS: 'Torkade Blodfläckar',
    VENOUS_BLOOD: 'Venöst Blod',
    QUANTISAL: 'Quantisal',
  },
  testType: {
    title: 'Skapa remiss för',
    typeOptions: {
      DoA: 'Drogpanel',
      PEth: 'Alkohol (PEth)',
    },
    confirmTestTypeChange:
      'Vänligen notera att om produkten ändras kommer all redan ifylld information rensas förutom "Personlig Information".',
  },
  specimenType: {
    specimen_type: 'Provmaterial',
    title: 'Typ av provmaterial',
    select: 'Fyll i provmaterial',
    types: {
      urine: 'Urin',
      saliva: 'Saliv',
      breath: 'Utandningsprov',
      DOA_URINE_SAMPLING: 'Urin',
      DOA_SALIVA_SAMPLING: 'Saliv',
      DOA_BREATH_SAMPLING: 'Utandningsluft',
      PETH_DRIED_BLOOD_SPOTS: 'Blod',
    },
  },
  substances: {
    title: 'Välj panel',
    panelHeading: 'Substanser',
    panel: {
      accredited: '{numberOfAccredited} ackrediterade substanser',
      unaccredited:
        '{numberOfUnaccredited} ej ackrediterad substans¹ | {numberOfUnaccredited} ej ackrediterade substanser¹',
      allAccreditedOrUnaccredited:
        '{numberOfSubstances} substans | {numberOfSubstances} substanser',
      cutoffWithinParenthesis: 'Rapporteringsgräns inom parantes.',
    },
    panelTitle: 'Panel',
    name: 'substanser',
    additional: 'Ytterligare',
    total: 'Total',
    S: 'Liten',
    M: 'Medium',
    L: 'Stor',
    SG: 'Alkoholmarkör',
    CHIRAL: 'Endast Kiral Analys{superscript} av Amfetamin',
    addAdditional: 'Lägg till ytterligare substanser',
    additionalSelected: 'Ytterligare ({selected})',
    cancelAdditional: 'Rensa ytterligare substanser',
    saveAdditional: 'Spara',
    names: {
      AMPHETAMINE: 'Amfetamin',
      METHAMPHETAMINE: 'Metamfetamin',
      MDMA: 'MDMA',
      METHYLPHENIDATE: 'Metylfenidat',
      COCAINE: 'Kokain',
      BENZOYLECGONINE: 'Bensoylekgonin',
      EPHEDRINE: 'Efedrin',
      '3_CMC': '3-CMC',
      PMMA: 'PMMA',
      '4_FA': '4-FA',
      PENTEDRONE: 'Pentedron',
      MEPHEDRONE: 'Mefedron',
      MDPV: 'MDPV',
      ALPHA_PVP: 'Alfa-PVP',
      KETAMINE: 'Ketamin',
      THC: 'THC',
      MORPHINE: 'Morfin',
      '6_MAM': '6-acetylmorfin',
      METHADONE: 'Metadon',
      BUPRENORPHINE: 'Buprenorfin',
      TRAMADOL: 'Tramadol',
      CODEINE: 'Kodein',
      OXYCODONE: 'Oxikodon',
      HYDROMORPHONE: 'Hydromorfon',
      DIAZEPAM: 'Diazepam',
      OXAZEPAM: 'Oxazepam',
      ALPRAZOLAM: 'Alprazolam',
      CLONAZEPAM: 'Klonazepam',
      FLUNITRAZEPAM: 'Flunitrazepam',
      TEMAZEPAM: 'Temazepam',
      LORAZEPAM: 'Lorazepam',
      ZOPICLONE: 'Zopiklon',
      ZOLPIDEM: 'Zolpidem',
      PREGABALIN: 'Pregabalin',
      GABAPENTIN: 'Gabapentin',
      FENTANYL: 'Fentanyl',
      DEXTROMETHORPHAN: 'Dextrometorfan',
      MITRAGYNINE: 'Mitragynin',
      OH_PHENAZEPAM: 'OH-Fenazepam',
      '7_AMINOCLONAZEPAM': '7-aminoklonazepam',
      EDDP: 'EDDP',
      ZOLPIDEM_ACID: 'Zolpidemsyra',
      RITALINIC_ACID: 'Ritalinsyra',
      OH_ALPRAZOLAM: 'OH-Alprazolam',
      '7_AMINOFLUNITRAZEPAM': '7-aminoflunitrazepam',
      THC_COOH: 'THC-COOH',
      CREATININE: 'Kreatinin',
      AMYLASE: 'Amylas',
      PHOSPHATIDYLETHANOL: 'Fosfatidyletanol - PEth (långtidsmarkör alkohol)',
    },
    chiralAnalysis:
      'Utför kiral analys¹ av amfetamin, om amfetaminresultat är positiv',
    ACCRED_LAB: 'Ackred. lab nr 10532 av Swedac för provning enl. ISO 15189',
    METHOD_NOT_ACCREDITED_QUALITY_ASSURED:
      '¹indikerar att analysen ej ingår i ackrediteringen, dock kvalitetssäkrad.',
    METHOD_REPORTED_QUALITATIVE:
      'indikerar att resultatet endast rapporteras kvalitativt, positivt (POS) / negativt (NEG), ej med en koncentration.',
  },
  comment: {
    title: 'Kommentar',
    prescriptions: 'Anteckna till exempel receptbelagda läkemedel',
    no_prescriptions: 'Personen har inga receptbelagda läkemedel',
    no_comments: 'Inga kommentarer',
    placeholder: 'Skriv in en kommentar',
  },
  temperature: {
    title: 'Urintemperatur',
    not_normal: 'Avvikande',
    range: '32-38',
    degrees_celsius: '°C',
    not_in_range_dialog: {
      title: 'Urintemperatur är avvikande',
      help_text:
        'Temperaturen är inte i accepterat interval och kan inte skickas på analys. Överväg att göra om testet.',
      dismiss_button: 'Avbryt remiss',
    },
  },
  barcode: {
    barcode: 'Streckkod',
    title: 'Skanna streckkod',
    barcodeReader: {
      label: 'Använd streckkodsläsare',
    },
    errorMessages: {
      barcode_duplicate:
        'Streckkoden har redan förbrukats. Skanna en ny streckkod.',
      barcode_does_not_exist:
        'Streckkoden existerar inte. Kontrollera att du skrivit in korrekt streckkod.',
    },
    manual_input: {
      required: 'Jag behöver skriva in streckkoden manuellt',
      input_label: 'Streckkod',
      verification_label: 'Verifiera streckkod',
    },
    cameraScanner: {
      cancel: 'Avbryt',
      loading: 'Laddar in kameran...',
      label: 'Kamera på din enhet',
      compatibilityMessage:
        'Notera: Funktionen är inte nödvändigtvis kompatibel med alla enheter/webbläsare.',
      barcodeAlignmentMessage: 'Vänligen håll streckkoden rak och horisontell.',
      errorMessages: {
        NotAllowedError:
          'Inställningar i din webbläsare tillåter inte att öppna kameran på din enhet. Justera dina inställningar och försök igen.',
        NotFoundError: 'Det går inte att hitta någon kamera på din enhet.',
        NotReadableError: 'Okänt fel. Det gick inte att ladda in kameran.',
        OverconstrainedError: 'Kameran på din enhet är inte kompatibel.',
        StreamApiNotSupportedError:
          'Din webbläsare saknar stöd för att använda kameran. Säkerställ att din webbläsare är uppdaterad och försök igen.',
        unknownError: 'Okänt fel. Det gick inte att ladda in kameran.',
      },
      modalAccept: 'Ok',
      modalHeader: 'Fel',
    },
  },
  nationality: {
    placeholder: 'Välj nationalitet',
    swedish: 'Ja',
    non_swedish: 'Nej',
    other: 'Annan',
  },
  identifier: {
    identifier: 'Identifikation',
    selectIdentifier: 'Välj identifikation',
    typeOfIdentifier: 'Identifikationstyp',
    nationalId: 'Nationellt ID kort',
    workplaceId: 'Arbetsplats ID',
    personalNumber: 'Personnummer',
    custom: 'Person-ID',
  },
  treatmentCenter: {
    title: 'Fyll i rehabiliteringscenter',
    selectTreatmentCenter: 'Välj rehabiliteringscenter',
    treatmentCenter: 'Rehabiliteringscenter',
  },
  employer: {
    title: 'Ange arbetsgivarinformation',
    sessionIdentifier: 'Session ID',
    selectSessionId: 'Välj session ID',
    employer: 'Arbetsgivare',
    selectEmployer: 'Välj arbetsgivare',
    subdivision: 'Underavdelning',
    no_subdivisions_tooltip:
      'Det här fältet är inaktiverat om det inte finns några underavdelningar',
    subcontractor: 'Ytterligare information',
  },
  personalIdentifier: {
    mask: 'ÅÅÅÅMMDDXXXX',
  },
  general: {
    continue: 'Gå vidare',
    welcome: 'Välkommen!',
    log_out: 'Logga ut',
    create_requisition: 'Skapa Remiss',
    view_results: 'Visa Resultat',
    view_requisitions: 'Visa Remisser',
    view_orders: 'Visa Remisser',
    randomizer: 'Randomiserare',
    sessions: 'Sessioner',
    next_step: 'Nästa',
    download: 'Ladda ner',
    signing_you_in: 'Loggar in dig...',
    unknown_error: 'Okänt fel',
    error_occurred: 'Ett fel uppstod',
    error_is_reported_message: 'Vårt team har informerats och jobbar på det.',
    offline_error:
      'Du är för närvarande offline. Vänligen kontrollera din internetanslutning och försök igen.',
    admin_board: 'Admin Board',
    no_available_test_types:
      'Din organisation har inga aktiverade testtyper. Kontakta din organisations administratör.',
    auth0_mismatch_org_error_key:
      'Du verkar för närvarande vara inloggad på flera olika organisationer, men bör endast vara inloggad på en åt gången. Vänligen logga ut och in igen för att fortsätta.',
    language: 'Språk',
    swedish: 'Svenska',
    english: 'Engelska',
  },
  authentication: {
    activate_bankid: 'Aktivera BankID',
    bankid_link_summary: 'Aktiveringen lyckades',
    bankid_link_error_summary: 'Aktiveringen misslyckades',
    bankid_link_error_detail_message:
      'Aktiveringen av BankID misslyckades, vänligen försök igen.',
    modal: {
      abort: 'Avbryt',
      no: 'Nej',
      yes: 'Ja',
      retry: 'Försök igen',
      login: 'Logga in',
      continue: 'Fortsätt',
      link: 'Länka konto',
      unlinked_bankid:
        'Ditt BankID är inte kopplat till något befintligt CareOS konto, vill du länka till ett?',
      not_part_of_org:
        'Du är inte del av organisationen som du försöker logga in på. Vänligen kontakta din administratör, eller försök igen.',
      user_cancel_link:
        'Det ser ut som att du avbröt BankID-identifieringen, vill du försöka igen?',
      user_cancel_login:
        'Det ser ut som att du avbröt BankID-identifieringen, vill du försöka igen?',
      user_not_found:
        'Vi kunde inte hitta ditt konto, vänligen kontakta din administratör.',
      link_proposal:
        'Vi stödjer nu BankID för att logga in på ditt CareOS-konto. Vill du länka ditt BankID till ditt konto? Om du vill göra det senare hittar du alternativet i menyn uppe till höger.',
      link_success:
        'Ditt BankID har kopplats till ditt konto, för att fortsätta, vänligen logga in igen.',
      link_error:
        'Något gick fel under aktiveringen av BankID, vill du försöka igen?',
      link_expired:
        'Länken har gått ut, kontakta din administratör om du inte har hunnit sätta upp ditt konto inom den givna tiden.',
      update_failed:
        'Det gick inte att länka ditt BankID till ditt konto, detta kan betyda att det redan har gjorts. Försök att logga in med BankID och ifall det inte fungerar, kontakta din administratör.',
      timeout: 'Tiden för att autentisera har gått ut, vill du försöka igen?',
      register_with_bankid:
        'Välkommen till CareOS, för att registrera med BankID, var god tryck fortsätt.',
      unknown: 'Ett okänt autentiseringsfel inträffade, vänligen försök igen.',
    },
  },
  signing: {
    header: 'Signering av remiss',
    description: 'Du signerar remiss med transaktions-ID: *{transactionId}*',
    footer:
      'Se till att du har läst och förstått remissen, och att du har verifierat att transaktions-ID ovan stämmer överens med det som visas på remissen.',
  },
  sessions: {
    header: {
      sessions: 'Sessioner',
      requisitions_without_session: 'Remisser utan session ({count})',
    },
    table: {
      columns: {
        sampling_date: 'Provtagnings-datum',
        session_id: 'Session-ID',
        employee_name: 'Anställd-ID',
        test_type: 'Testtyp',
        reason: 'Anledning',
        status: 'Status',
        actions: 'Åtgärder',
      },
      filters: {
        clear: 'Rensa',
        apply: 'Tillämpa',
        january: 'Januari',
        february: 'Februari',
        march: 'Mars',
        april: 'April',
        may: 'Maj',
        june: 'Juni',
        july: 'Juli',
        august: 'Augusti',
        september: 'September',
        october: 'Oktober',
        november: 'November',
        december: 'December',
        sunday: 'Sö',
        monday: 'Må',
        tuesday: 'Ti',
        wednesday: 'On',
        thursday: 'To',
        friday: 'Fr',
        saturday: 'Lö',
      },
      employee_name_tooltip:
        'Håll muspekaren över det personliga namnet för att se alla personliga detaljer',
      reasonForTesting: {
        random_selection: 'Slumpmässig',
        new_employee: 'Nyanställning',
        incident_accident: 'Tillbud/olycka',
      },
      session_status: {
        started: 'Startad',
        finished: 'Avslutad',
        requested: 'Begärd',
        reported: 'Rapporterad',
      },
      actions_tooltip: {
        randomizer: 'Generera slumpmässig ordning',
        start_session: 'Starta session',
        session_started_toast_msg: 'Session har startats.',
        close_session: 'Stäng session',
      },
    },
    close_session: {
      modal: {
        message:
          'Är du säker på att du vill avsluta sessionen med ID {sessionId}?',
        note: 'Notera:',
        close_button: 'Stäng session',
        session_closed_toast_msg: 'Session har avslutats.',
      },
    },
  },
  requisitions_without_session: {
    table: {
      columns: {
        sampling_date: 'Provtagnings-datum',
        barcode: 'Streckkod',
        employer: 'Arbetsgivare',
        subDivision: 'Underavdelning',
        employee_name: 'Anställd-ID',
        test_type: 'Testtyp',
        reason: 'Anledning',
      },
    },
  },
  results: {
    summary_report: 'sammanfattnings-rapport',
    download_filtered_content:
      'Ladda ner rapport med {n} resultat | Ladda ner sammanfattning med {n} resultat',
    pos: 'Se rapport',
    neg: 'Negativ',
    inv: 'Ogiltig',
    peth_result: 'PEth-rapport',
    tableEmpty:
      'Inga resultat att visa med nuvarande filterinställningar. (Visar {numberOfResults} av {numberOfTotalResults} resultat).',
    received: 'Resultat rapporterade',
    awaiting: 'Väntar på resultat',
    anomalies: {
      EMPTY_TUBE: 'Tomt provrör',
      ABSORBENT_MATERIAL_INSIDE: 'Absorberande material i provrör',
      INADEQUATE_SAMPLE: 'Otillräckligt prov',
      TAMPER_SEAL_BROKEN: 'Manipulerad försegling',
      LEAKAGE: 'Läckage',
      DEBRIS_IN_SAMPLE: 'Skräp i prov',
      UNSCANNABLE_BARCODE: 'Oläsbar streckkod',
      INCORRECT_BARCODE_PLACEMENT: 'Felaktigt placerad streckkod',
      CONTAMINATED_SAMPLE: 'Förorenat prov',
      NO_BARCODE: 'Ingen streckkod',
      DUPLICATE_BARCODE: 'Dubblett av streckkod',
      MISSING_REQUISITION: 'Saknad remiss',
      MISSING_B_SPECIMEN: 'Saknat A eller B-prov',
      MISMATCHED_B_SPECIMEN: 'B-prov av annan typ',
      MISMATCHED_SPECIMEN_TYPE: 'Inkonsekvent provtyp',
      LATE_REQUISITION: 'Sen remiss',
      NO_TAMPER_SEAL: 'Ingen manipuleringsförsegling',
      INSUFFICIENT_SAMPLING:
        'Otillräcklig provtagning (för lite provtagningsmaterial)',
      UNKNOWN_SAMPLING_KIT: 'Felaktigt provtagnings-kit',
      MISMATCHED_SAMPLING_KIT: 'Ej-överensstämmande provtagnings-kit',
    },
  },
  summary: {
    additional_substance: 'Ytterligare substanser',
    alcohol_marker: 'Alkoholmarkör',
    confirm_requisition: 'Bekräfta remiss',
    hand_sign: 'Underteckna för hand',
    bankid_sign: 'Signera med BankID',
    sign: 'Signera',
    submit_order: 'Skapa beställning',
    try_again: 'Försök igen',
    submit: 'Skapa',
    collector_approval_workplace:
      'Genom att skapa beställning intygar du som provtagare att provgivaren har identifierats korrekt, att provtagningen har utförts korrekt, och att provgivaren fått relevant information och observerats vid undertecknandet av samtycke.',
    collector_approval_treatment_center:
      'Genom att skapa beställning intygar du som provtagare att provgivaren har identifierats korrekt, att provtagningen har utförts korrekt, och att provgivaren fått relevant information.',
    chiral_header: 'Kiralt Amfetamin',
    chiral_text: 'Kiral analys av amfetamin, om amfetaminresultat är positivt.',
    error_message: {
      creating_order:
        'Det gick inte att skapa remissen. Vänligen försök igen eller kontakta din kundrepresentant.',
      personal_number_mismatch: 'Personnummer överensstämmer inte',
      personal_number_mismatch_description:
        'Personnumret från BankID stämmer inte överens med det som angavs i remissen.',
      signing_requisition:
        'Det gick inte att signera remissen. Vänligen försök igen.',
    },
  },
  created: {
    requisition_created: 'Remiss skapad!',
    create_new: 'Skapa ny remiss',
    return_to_start: 'Återgå till startskärm',
    hide_details: 'Dölj detaljer',
    show_details: 'Visa detaljer',
    requisition_password: 'Lösenord för remiss',
    remind_donor_note_password:
      'Se till att donatorn skriver ner eller fotograferar detta lösenord. Lösenordet krävs för att donatorn ska kunna se bekräftelsen av sitt samtycke och provtagning.',
    organization_details: 'Organisationsinformation',
    organization: 'Organisation',
    collector: 'Provtagare',
    collector_email: 'Provtagarens e-post',
  },
  table: {
    fetchingData: 'Hämtar data, var god vänta...',
    apply: 'Tillämpa',
    clear: 'Rensa',
    any: 'Alla',
    acknowledged: 'Hanterad',
    drug_panel: 'Drogpanel',
    chiral_analysis: 'Kiral analys',
    see_result: 'Se resultat',
    clear_filter: 'Töm filter',
    columns: {
      patientIdentifier: 'Person-ID',
      samplingDate: 'Provtagnings-datum',
      resultReceived: 'Resultat inkommet',
      result: 'Resultat',
      organization: 'Organisation',
      employer: 'Arbetsgivare',
      subDivision: 'Underavdelning',
      collector: 'Provtagare',
      barcode: 'Streckkod',
      treatmentCenter: 'Rehabiliteringscenter',
      receivedAtLabDate: 'Mottaget på lab',
      testType: 'Testtyp',
    },
  },
  consent_form: {
    requisition_drug_test: 'Remiss för drogtest',
    DoA_consent_text:
      'Jag bekräftar att jag har tagit del av information om detta drogtest samt dess innebörd och innehåll. Jag bekräftar att jag har lämnat ett färskt urin- eller salivprov till provtagaren. Jag har observerat att provmaterialet placerats och förslutits i provrör och jag bekräftar att informationen som visas ovan samt på provrören är korrekt. Jag ger härmed tillåtelse att minst två förseglade provrör skickas till ABC Labs och jag samtycker till att de analyseras för att hitta tecken på droganvändning samt att tester kommer att utföras för att bekräfta provets giltighet. Vidare förstår jag att resultaten kommer att kommuniceras konfidentiellt till arbetsgivaren eller annan utsedd representant.',
    PEth_consent_text:
      'Jag bekräftar att jag har tagit del av information om detta alkoholtest samt dess innebörd och innehåll. Jag bekräftar att jag har lämnat ett färsk blodprov till provtagaren. Jag har observerat att provmaterialet placerats och förslutits i provkort/provrör och jag bekräftar att informationen som visas ovan samt på provkorten/provrören är korrekt. Jag ger härmed tillåtelse att minst ett förseglat provkort/provrör skickas till ABC Labs och jag samtycker till att det analyseras för att hitta tecken på långvarig alkoholkonsumtion samt att tester kommer att utföras för att bekräfta provets giltighet. Vidare förstår jag att resultaten kommer att kommuniceras konfidentiellt till arbetsgivaren eller annan utsedd representant.',
    created: 'Skapad :',
    signature: 'Signatur',
  },
  identifier_system: {
    'urn:oid:127521292131': 'Svenskt personnummer',
    date_of_birth: 'Födelsedag',
  },

  signed_consent: 'Undertecknat samtycke',
  sign_consent: 'Underteckna samtycke',
  form: {
    comment: 'Kommentar',
  },
  patientConfirmation: {
    passcode: {
      inputLabel: 'Lösenord för remiss',
      submitButton: 'Visa remiss',
      help: 'Ange koden du fick från provtagaren efter provtagningen',
      codeInvalid:
        'Remisslösenordet du angav är felaktigt. Var god försök igen!',
    },
  },
  acknowledged: {
    true: 'Hanterad',
    false: 'Ej hanterad',
  },
  admin_board: {
    tabs: {
      employers: 'Arbetsgivare',
      treatment_centers: 'Rehabiliteringscenter',
      users: 'Användare',
    },
    table: {
      not_configured: 'Behöver konfigureras',
      headers: {
        name: 'Namn',
        added: 'Tillagd',
        login_name: 'Inloggningsnamn',
        type: 'Typ',
        created_at: 'Skapad',
        created_at_tooltip:
          'Datumet i detta filter kan vara antingen ett ensta datum eller ett datumintervall, ett intervall kan väljas genom att klicka på två datum.',
        employer: 'Arbetsgivare',
        email: 'E-post',
        role: 'Roll',
        subdivision: 'Underavdelning',
        treatment_center: 'Rehabiliteringscenter',
        notification: 'Resultat-notifikation',
      },
      edit_row: {
        confirm_1: 'Du är på väg att göra följande ändringar:',
        confirm_2: 'Aktiverade testtyper för organisationen kommer att vara:',
        confirm_none:
          'Inga. Om du väljer att acceptera kommer det inte vara möjligt att beställa något test.',
        enable_doa: 'Drogpanel',
        enable_peth: 'Alkohol (PEth)',
        enable: 'Aktivera {testType}',
        enable_non_coc: 'Aktivera {testType} *(non-CoC)',
        disable: 'Inaktivera {testType}',
        confirmation_1: 'För din organisation {organization} är du på väg att:',
        confirmation_2: 'Vill ni fortsätta?',
        enable_sampling_kits_prompt: 'Aktivera {samplingKits}',
        disable_sampling_kits_prompt: 'Inaktivera {samplingKits}',
        cancel: 'Avbryt',
        submit: 'Skicka',
        go_back: 'Gå tillbaka',
        confirm: 'Bekräfta',
      },
      filters: {
        Collector: 'Provtagare',
        Admin: 'Admin',
        clear: 'Rensa',
        apply: 'Tillämpa',
        january: 'Januari',
        february: 'Februari',
        march: 'Mars',
        april: 'April',
        may: 'Maj',
        june: 'Juni',
        july: 'Juli',
        august: 'Augusti',
        september: 'September',
        october: 'Oktober',
        november: 'November',
        december: 'December',
        sunday: 'Sö',
        monday: 'Må',
        tuesday: 'Ti',
        wednesday: 'On',
        thursday: 'To',
        friday: 'Fr',
        saturday: 'Lö',
      },
    },
    org_types: {
      workplace: 'Arbetsplats',
      treatment_center: 'Rehabiliteringscenter',
    },
    add_org_form: {
      name: 'Namn',
      type: {
        label: 'Typ av provtagning',
        placeholder: 'Välj en typ',
        workplace: 'Arbetsplats',
        treatment_center: 'Rehabiliteringscenter',
      },
      submit_button: 'Lägg till organisation',
      org_added_msg: 'Organisation tillagd!',
      confirm_add_organization: {
        title: 'Bekräfta namn för organisation',
        text: 'Är du säker på att du vill lägga till en organisation med namnet',
        login_name: 'Inloggningsnamn:',
      },
    },
    add_employer_form: {
      employer_added_message: 'Arbetsgivare tillagd!',
      employer_name: 'Arbetsgivare',
      add_employer: 'Lägg till arbetsgivare',
    },
    employer_table: {
      add_subdivision: 'Lägg till underavdelning',
    },
    edit_employers_modal: {
      title: 'Bekräfta att byta namn på arbetsgivarnamn',
      message_1: 'Du håller på att ändra arbetsgivarnamnet från',
      message_2: 'till',
      employer_edited_toast_msg: {
        success: 'Arbetsgivarens namn har uppdaterats.',
        error: 'Arbetsgivarens namn får inte vara tomt.',
      },
    },
    add_tc_form: {
      tc_added_message: 'Rehabiliteringshem tillagd!',
      tc_name: 'Rehabiliteringshem',
      add_tc: 'Lägg till rehabiliteringshem',
    },
    edit_tc_modal: {
      title: 'Bekräfta att byta namn på Rehabiliteringshem',
      message_1: 'Du håller på att ändra Rehabiliteringshem från',
      message_2: 'till',
      tc_edited_toast_msg: {
        success: 'Rehabiliteringshem namn har uppdaterats framgångsrikt.',
        error: 'Rehabiliteringshem namn får inte vara tomt.',
      },
    },
    add_user_form: {
      add_user: 'Lägg till användare',
      name: 'Namn',
      email: 'E-post',
      role: 'Roll',
      select_role: 'Välj en roll',
      modal: {
        already_exists:
          'Användaren finns redan i den här organisationen under ett annat namn: ',
        add_anyway: 'Vill du lägga till användaren med det existerande namnet',
        contact_text:
          'Kontakta din organisations administratör om du behöver uppdatera en användare.',
      },
      max_length_warning: 'Användarnamn kan vara upp till 80 tecken.',
    },
    add_subdiv_form: {
      subdiv_added_message: 'Underavdelning tillagd!',
    },
    edit_subdiv_modal: {
      title: 'Bekräfta att byta namn på underavdelningen',
      message_1: 'Du håller på att ändra underavdelningen från',
      message_2: 'till',
      subdiv_edited_toast_msg: {
        success: 'Underavdelningens namn har uppdaterats.',
        error: 'Namnet på underavdelningen får inte vara tomt.',
      },
    },
    user_already_exists_on_org:
      'Användaren finns redan i den här organisationen',
    user_added_msg: 'Användare tillagd!',
    user_edited_msg: 'User edited!',
    user_deleted_msg: 'Användare borttagen',
    organization_login_name: 'Inloggningsnamn',
    remove_user_heading:
      'Du kommer att ta bort användaren med följade information',
    user_details: {
      changes: 'Du kommer göra följande ändringar',
      current: 'Nuvarande',
      new: 'Nya',
      name: 'Namn',
      email: 'Email',
      role: 'Behörighet',
      role_collector: 'Provtagare',
      role_collector_long: 'Provtagare - Endast skapa remisser',
      role_hr: 'HR WPS',
      role_hr_long: 'HR WPS - Skapa ordrar och se resultat',
      role_admin: 'Admin',
      role_admin_long: 'Admin - Se resultat och skapa remisser',
      reset_auth_tooltip: 'Återställ MFA',
    },
    notification: {
      modal: {
        msg_prefix: 'Du kommer göra följande ändringar:',
        activate: 'aktivera',
        deactivate: 'avvaktivera',
        msg_suffix: 'resultat notifikationer för användaren',
      },
      response_msg: {
        success: 'Notifikationsinställning uppdaterad',
        error: 'Uppdatering av notifikationsinställning kunde inte genomföras',
      },
      tooltip: {
        activate: 'Aktivera notifikationer',
        deactivate: 'Avvaktivera notifikationer',
      },
      request_update_error:
        'Notifikationsinställning kunde inte uppdateras. Orsak: ',
    },
    send_email: {
      tool_tip: 'Skicka välkomstmail',
      modal: {
        msg_prefix: 'Du kommer skicka välkomstmail för användaren:',
        msg_suffix:
          'Följ instruktionerna i mailet för aktivering av konto. Aktiveringslänk är giltig i 5 dagar.',
      },
    },
    mfa_modal: {
      msg_prefix: 'Vill du återställa multifaktorautentisering för användaren',
      msg_suffix:
        'Detta kräver att användaren återregistrerar sig för multifaktorautentisering.',
    },
    submit_modal: {
      accept: 'Acceptera',
      decline: 'Avbryt',
    },
  },
  careos_table: {
    default_empty_msg: 'Det finns ingen data',
    loading: 'Laddar...',
  },
  randomizer_form: {
    total_on_site: 'Anställda på plats',
    total_to_test: 'Anställda att testa',
    button_label: 'Generera',
  },
};
