<script setup lang="ts">
import VuePdfEmbed from 'vue-pdf-embed';
import type { Source } from 'vue-pdf-embed/dist/types/types';
// essential styles
import 'vue-pdf-embed/dist/style/index.css';
// optional styles
import 'vue-pdf-embed/dist/style/annotationLayer.css';
import 'vue-pdf-embed/dist/style/textLayer.css';

import { useElementSize } from '@vueuse/core';
import { ref } from 'vue';

export type PdfStatus = 'loading' | 'loaded' | 'rendered';

const emit = defineEmits<{
  onRendered: [];
  onLoaded: [];
  onUpdateStatus: [status: PdfStatus];
}>();

defineProps<{
  pdfSource: Source;
}>();

const containerRef = ref<HTMLDivElement | null>(null);
const { width } = useElementSize(containerRef);

const handleRendered = () => {
  emit('onRendered');
  emit('onUpdateStatus', 'rendered');
};

const handleLoaded = () => {
  emit('onLoaded');
  emit('onUpdateStatus', 'loaded');
};
emit('onUpdateStatus', 'loading');
</script>

<template>
  <div ref="containerRef" class="w-full">
    <VuePdfEmbed
      :source="pdfSource"
      :width="width"
      class="space-y-2"
      @rendered="handleRendered"
      @loaded="handleLoaded"
      :text-layer="true"
    />
  </div>
</template>

<style>
.vue-pdf-embed > div {
  @apply [box-shadow:0_2px_8px_4px_rgba(0,0,0,0.1)];
}
</style>
