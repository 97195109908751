<script setup lang="ts">
import { type Locale, useI18n } from '../../composables/i18n';
import type { BankIdRequestErrorResponseDto } from 'careos-bankid-adapter';

export type ScanError =
  | BankIdRequestErrorResponseDto
  | { errorCode: 'careosUnknown' };

const props = defineProps<{
  error: ScanError;
  locale: Locale;
}>();
const { t } = useI18n(props.locale);
</script>

<template>
  <div class="grid place-content-center h-full">
    <h2 class="text-3xl font-extrabold">
      {{ t(`errorcode-${error.errorCode}`) }}
    </h2>
  </div>
</template>
