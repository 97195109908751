// css
import '@careos/tailwindcss/index.css';
import 'primeicons/primeicons.css';
import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/lara-light-blue/theme.css';

// Formkit
import { formKitConfig } from '@/formkit/formkit-config';
import { plugin } from '@formkit/vue';
// Pinia
import { createPinia } from 'pinia';
// Primevue
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import { canDirective } from './directives/can';

// Vue
import { createApp } from 'vue';
import App from './App.vue';
import { auth0 } from './authentication/auth0';
import { router } from './router/index';
import { i18n } from './translations/i18n';

// Sentry
import * as Sentry from '@sentry/vue';
import { isProd, isStaging } from '@/utils/deployment-environment';

const app = createApp(App);

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        /^localhost:3000(\/.*)?$/,
        /^https:\/\/staging-doa\.careos\.io\/\??.*/,
        /^https:\/\/toxicology\.careos\.io\/\??.*/,
      ],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay({
      networkDetailAllowUrls: [/^https:\/\/toxicology\.careos\.io\/\??.*/],
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: isProd ? 1.0 : 0.0,
  environment: isStaging ? 'staging' : isProd ? 'production' : 'development',
  sendClientReports: false,
  logErrors: true,
  trackComponents: true,
  attachProps: true,
  attachStacktrace: true,
  ignoreErrors: [
    'error loading dynamically imported module',
    'invalid assignment',
  ],
});

app.use(router);
app.use(ToastService);
app.use(createPinia());
app.use(plugin, formKitConfig());
app.use(i18n);
app.use(PrimeVue, {
  locale: {
    firstDayOfWeek: 1,
  },
});
app.use(auth0);
app.directive('can', canDirective);
app.directive('tooltip', Tooltip);

app.mount('#app');
